(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('updateQueue', updateQueue);

    updateQueue.$inject = [
        '$q',
        '$rootScope',
        'appSettings',
        'cacheService',
        'healthInfoService',
        'settingsService',
        'settingKeys',
        'updateQueueUtils'
    ];

    var updateActionTypes = [];

    function updateQueue(
        $q,
        $rootScope,
        appSettings,
        cacheService,
        healthInfoService,
        settingsService,
        settingKeys,
        updateQueueUtils
    ) {
        var cacheKey = '_updateQueue';
        var lock = false;
        let isBulkModeEnabled = true;

        var updateQueue = {
            addUpdateAction: addUpdateAction,
            setUpdateActionTypes: setUpdateActionTypes,
            triggerUpdates: triggerUpdates
        };

        return updateQueue;

        function setUpdateActionTypes(actionTypes) {
            if (actionTypes && actionTypes.length) {
                updateActionTypes = [];
                _.forEach(actionTypes, actionType => updateActionTypes.push(actionType));
            }
        }

        function addUpdateAction(updateAction) {
            var deferred = $q.defer();

            cacheService.prependTo(cacheKey, updateAction).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        async function getUpdateQueue() {
            let updateQueue = await cacheService.get(cacheKey);
            await new Promise(resolve => setTimeout(resolve, appSettings.updateQueueTriggerWaitMs));
            updateQueue = await cacheService.get(cacheKey);
            return updateQueue;
        }

        async function triggerUpdates(nested) {
            if (!$rootScope.isOnline || (lock && !nested)) return;
            
            lock = true;

            const updateQueue = await getUpdateQueue();

            if (!updateQueue?.length) {
                lock = false;
                return;
            }

            const queueItemProcessCount = await settingsService.getOne(settingKeys.QueueItemProcessCount);
            const isBulkMode = isBulkModeEnabled && queueItemProcessCount > 1;
            const updateActions = updateQueue.slice();
            const lastUpdateAction = updateActions[updateActions.length - 1];

            if (!lastUpdateAction) {
                lock = false;
                return;
            }

            const lastUpdateActions = isBulkMode ? updateActions.slice(-appSettings.queueItemProcessCount) : [lastUpdateAction]

            tryCatchLabel: try {
                if (isBulkMode) {
                    await bulkCallback(lastUpdateActions);
                    const ids = lastUpdateActions.map(x => x.id);
                    const removeRequests = ids.map(id => cacheService.removeFrom(cacheKey, null, "id", id));
                    await Promise.all(removeRequests);
                } else {
                    await callback(lastUpdateAction);
                    await cacheService.removeFrom(cacheKey, null, "id", lastUpdateAction.id);
                }
            } catch (e) {
                if (isBulkModeEnabled) {
                    isBulkModeEnabled = false;
                    break tryCatchLabel;
                }
                if (lastUpdateAction.type === "uploadImage" || lastUpdateAction.type === "updateRouteStop") {
                    const routeLineId = lastUpdateAction.parameters[1] || null;
                    healthInfoService.handleAddNewWrongRouteStopUploadRequest(routeLineId);
                }

                if (lastUpdateAction.type === "uploadImage") {
                    await postPoneImageWithDependents(cacheKey, lastUpdateAction);
                    break tryCatchLabel;
                }

                if (lastUpdateAction.isPostponable) {
                    await postponeAction(cacheKey, lastUpdateAction);
                    break tryCatchLabel;
                }

                if (lastUpdateAction.retryCount === 0) {
                    await cacheService.removeFrom(cacheKey, null, "id", lastUpdateAction.id);
                    break tryCatchLabel;
                } else if (lastUpdateAction.retryCount != null) {
                    lastUpdateAction.retryCount -= 1;
                    await cacheService.replaceIn(cacheKey, null, lastUpdateAction, "id", lastUpdateAction.id);
                    break tryCatchLabel;
                }
            } finally {

                if (lastUpdateAction) {
                    return triggerUpdates(true);
                }

                if (!nested) {
                    lock = false;
                }
            }
        }

        async function postPoneImageWithDependents(cacheKey, lastUpdateAction) {
            const orderId = lastUpdateAction?.parameters[0];
            const routeLineId = lastUpdateAction?.parameters[1];
          
            if (!orderId || !routeLineId) {
              throw new Error("Invalid orderId or routeLineId");
            }
          
            const updateQueue = await getUpdateQueue();
          
            if (!updateQueue) return;
            const updateActions = updateQueue.slice();
            const imageUpdateAction = updateActions.find(x => x.type === 'uploadImage' && x.parameters[0] === orderId && x.parameters[1] === routeLineId);
        
            const routeStopDependents = updateActions.filter(rs => rs.type === 'updateRouteStop' && rs.parameters[0] === orderId && rs.parameters[1] === routeLineId);
            const orderDependents = updateActions.filter(o => o.type === 'updateOrderStatus' && o.parameters[0] === orderId);
        
            const dependents = routeStopDependents.concat(orderDependents);
        
            const postponeArray = dependents.length ? [imageUpdateAction].concat(dependents) : [imageUpdateAction];
            const postponePromises = postponeArray.map(el => postponeAction(cacheKey, el));
        
            await Promise.all(postponePromises);
        }

        async function bulkCallback(updateActions) {
            const actionsGroupedByType = updateQueueUtils.getGroupedUpdateActionsFromQueue(updateActions);
            const {uploadImage: imageUploads = [], updateRouteStop: routeStopUpdates = [], ...rest} = actionsGroupedByType;
            const convertedRouteStopUpdates = routeStopUpdates.map(x => {
                const obj = {orderId: x.parameters[0], routeStopId: x.parameters[1]}
                return obj;
            });
            const otherUpdates = _.flatten(Object.values(rest));
            const bulkUpdateRouteStopsCallback = updateActionTypes.find(x => x.type === 'bulkUpdateRouteStops').callback

            await bulkImageUploadCallback(imageUploads);
            await bulkUpdateRouteStopsCallback(convertedRouteStopUpdates);
            const otherUpdateRequests = otherUpdates.map(async (x) => await callback(x));
            await Promise.all(otherUpdateRequests)
        }

        async function bulkImageUploadCallback(imageUploadActions) {
            if (!imageUploadActions?.length) return;
            for (const imageAction of imageUploadActions) {
                await callback(imageAction);
            }
        }

        function callback(updateAction) {
            var updateActionType = updateActionTypes.find(x => x.type === updateAction.type);

            return updateActionType
                ? updateActionType.callback.apply(this, updateAction.parameters)
                : $q.reject();
        }

        function postponeAction(cacheKey, lastUpdateAction) {
            var deferred = $q.defer();

            cacheService.removeFrom(cacheKey, null, 'id', lastUpdateAction.id)
                .then(() => {
                    return addUpdateAction(lastUpdateAction)
                })
                .then(() => {
                    deferred.resolve();
                })
                .catch(() => {
                    $log.Error("Failed to add update action" +
                        lastUpdateAction.parameters ? lastUpdateAction.parameters[0] : "no parameter");
                    deferred.reject();
                });

            deferred.resolve();

            return deferred.promise;
        }
    }
})();
