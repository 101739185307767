(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('FooterController', footerController);

    footerController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$window',
        'locationService',
        'cacheService',
        'routeStopsService',
        'appSettings',
        'settingsService',
        'translateService'
    ];

    function footerController(
        $rootScope,
        $scope,
        $state,
        $window,
        locationService,
        cacheService,
        routeStopsService,
        appSettings,
        settingsService,
        translateService
    ) {
        $scope.auto = false;
        $scope.lat = 'N/A';
        $scope.lng = '';
        $scope.speed = 'N/A';
        $scope.accuracy = 'N/A';
        $scope.time = 'N/A';
        $scope.isLocationExpired = false;
        $scope.currentState = null;
        $scope.sort = {};
        $scope.canDeleteOrder = false;
        $scope.isRouteStopsView = false;
        $scope.showOrderDropdown = false;
        $scope.showSplitViewIcon = false;
        $scope.showListIcon = false;
        $scope.exactDateToggleVisible = false;
        $scope.exactDateToggleValue = false;

        $scope.sortOptions = [];
        $scope.sortClicked = sortClicked;
        $scope.autoChanged = autoChanged;
        $scope.toggleOrderDeleteMode = toggleOrderDeleteMode;
        $scope.refreshOrders = refreshOrders;
        $scope.routeStopsSplitViewRequest = routeStopsSplitViewRequest;
        $scope.translate = translateService.translate;
       
        initController();
       
        $scope.$on('splitViewRequest',
            function(event, splitViewType) {
                routeStopsSplitViewRequest(splitViewType);
            });

        angular.element($window).bind('orientationchange', function () {
            if ($state.current.name === 'main.routeStops') {
                routeStopsSplitViewRequest($state.params.splitView);
            }
        });

        function initController() {
            settingsService.getAutoModeToggleButtonVisible().then(function (autoModeToggleButtonVisible) {
                $scope.autoModeToggleButtonVisible = autoModeToggleButtonVisible;
            });

            locationService.getCurrentPosition().then(function (position) {
                setFormattedPos(position);
            });

            $scope.$on('canDeleteOrder', canDeleteOrder);
            $scope.$on('locationChanged', locationChanged);
            $scope.$on('locationExpired', locationExpired);
            $scope.$on('routeStopsSortCustomized', routeStopsSortCustomized);

            stateChanged($state.current);

            $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                stateChanged(toState);
            });

            cacheService.get('_auto').then(function (autoValue) {
                $scope.auto = autoValue || $scope.auto;
                $rootScope.auto = autoValue;
                $rootScope.$broadcast('autoChange', $scope.auto);
            });

            if ($rootScope.userType === 'underground'
                || $rootScope.userType === 'service') {
                $scope.exactDateToggleVisible = true;

                cacheService.get('_exactDateToggleValue').then(function (exactDateToggleValue) {
                    $scope.exactDateToggleValue = exactDateToggleValue || $scope.exactDateToggleValue;
                    $rootScope.exactDateToggleValue = exactDateToggleValue;

                    $("#exact-date").kendoMobileSwitch({
                        checked: $scope.exactDateToggleValue,
                        onLabel: translateService.translate('general.on'),
                        offLabel: translateService.translate('general.off'),
                        change: exactDateChanged
                    });

                    $rootScope.$broadcast('exactDateChanged', $scope.exactDateToggleValue);
                });
            }

            initializeSort();
        }

        function sortClicked(sortOption) {
            changeSort(sortOption);
        }

        function autoChanged(e) {
            if (e.checked) {
                if (confirm(translateService.translate('footer.autoModeConfirmation'))) {
                    handleAutoChanged(e.checked);
                } else {
                    $scope.auto = false;
                    $rootScope.auto = false;
                }
            } else {
                handleAutoChanged(e.checked);
            }
        }

        function exactDateChanged(evt) {
            if (evt) {
                cacheService.set('_exactDateToggleValue', evt.checked);
                $scope.exactDateToggleValue = evt.checked;
                $rootScope.exactDateToggleValue = evt.checked;

                $rootScope.$broadcast('exactDateChanged', evt.checked);
            }
        }

        function routeStopsSplitViewRequest(type) {
            if (type === 'map' && ($window.innerWidth < 480 || ($scope.currentState && $scope.currentState.name !== 'main.routeStops'))) {
                $state.go('main.map',  {
                    orderId: $state.params ? $state.params.orderId : null,
                    completed: $state.params ? $state.params.status : null
                });
            } else {
                $state.params.splitView = type;
                //$state.params.reloadReportedRouteStops = false;

                $state.go('main.routeStops', $state.params, { reload: true });
            }

            routeStopsService.saveSplitViewStatus(type);
        }

        function toggleOrderDeleteMode() {
            if ($scope.canDeleteOrder || $rootScope.orderDeleteMode) {
                $rootScope.orderDeleteMode = !$rootScope.orderDeleteMode;
            } else {
                alert(translateService.translate('footer.noDeletableOrders'));
            }
        }

        function refreshOrders() {
            $rootScope.$broadcast('refreshOrders');
        }

        //Private functions
        function canDeleteOrder(evt, canDeleteOrder) {
            $scope.canDeleteOrder = canDeleteOrder;
        }

        function locationChanged(evt, position) {
            $scope.isLocationExpired = false;
            setFormattedPos(position);

            if (position && position.coords) {
                if (position.coords.speed !== null && position.coords.speed !== undefined) {
                    $scope.speed = parseFloat(position.coords.speed * 3.6).toLocaleString('no-NO', { maximumFractionDigits: 0 }) + ' Km/h';
                } else {
                    $scope.speed = 'N/A';
                }

                if (position.coords.accuracy !== null && position.coords.accuracy !== undefined) {
                    $scope.accuracy = parseFloat(position.coords.accuracy).toLocaleString('no-NO', { maximumFractionDigits: 2 });
                } else {
                    $scope.accuracy = 'N/A';
                }
            }

            if (position && position.timestamp) {
                if (position.timestamp !== null && position.timestamp !== undefined) {
                    $scope.time = moment(position.timestamp).format('HH:mm:ss.SSS');
                } else {
                    $scope.time = 'N/A';
                }
            }

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function locationExpired(evt) {
            $scope.isLocationExpired = true;
        }

        function routeStopsSortCustomized(evt, routeStopsSortCustomizedParams) {
            var action = routeStopsSortCustomizedParams.action;
            var sortOption = routeStopsSortCustomizedParams.sortOption;

            var existingOptionIndex = _.findIndex($scope.sortOptions, { value: sortOption.value });
            var exists = existingOptionIndex > -1;

            if (action === 'add' && !exists) {
                $scope.sortOptions.push(sortOption);
            } else if (action === 'addAndActivate' && !exists) {
                $scope.sortOptions.push(sortOption);
                changeSort(sortOption);
            } else if (action === 'remove' && exists) {
                $scope.sortOptions.splice(existingOptionIndex, 1);
                if ($scope.sort.value === sortOption.value) {
                    changeSort($scope.sortOptions[0]);
                }
            }
        }

        function changeSort(sortOption) {
            $scope.sort = sortOption;

            if ($state && $state.current && $state.params) {
                var updatedParams = $state.params;
                if (updatedParams.sortBy) {
                    updatedParams.sortBy = sortOption.value;
                }

                $state.transitionTo($state.current.name, updatedParams, { notify: false });
            }

            if (!isCustomSortOption(sortOption)) routeStopsService.saveSortBy(sortOption.value);

            $rootScope.$broadcast('routeStopsSortChanged', sortOption.value);
        }

        function isCustomSortOption(sortOption) {
            return !_.find(appSettings.sortOptions, { value: sortOption.value });
        }

        function stateChanged(newState) {
            $scope.isRouteStopsView = (newState.name === 'main.routeStops');
            var isOrdersView = (newState.name === 'main.orders');

            $scope.showOrderDropdown = $scope.isRouteStopsView;
            $scope.showSplitViewIcon = $scope.isRouteStopsView;
            $scope.showListIcon = $scope.isRouteStopsView || (newState.name === 'main.map');
            $scope.showMapIcon = $scope.isRouteStopsView || (newState.name === 'main.map');

            $scope.showToggleDeleteModeIcon = isOrdersView;
            $scope.showRefreshIcon = isOrdersView;

            $scope.currentState = newState;

            if ($scope.isRouteStopsView) initializeSort();
        }

        function initializeSort() {
            $scope.sortOptions = appSettings.sortOptions.slice();

            var sortBy = $state.params.sortBy || routeStopsService.getSortBy();
            var sortOption = _.find($scope.sortOptions, { value: sortBy });

            $scope.sort = sortOption ? sortOption : _.find(appSettings.defaultSortOptions, { default: true });
        }

        function setFormattedPos(position) {
            if (position && position.coords) {
                $scope.lat = parseFloat(position.coords.latitude).toLocaleString('no-NO', { maximumFractionDigits: 4 });
                $scope.lng = parseFloat(position.coords.longitude).toLocaleString('no-NO', { maximumFractionDigits: 4 });
            }
        }

        function handleAutoChanged(newValue) {
            cacheService.set('_auto', newValue);
            $rootScope.auto = newValue;
            $rootScope.$broadcast('autoChange', newValue);
        }
    }
})();
