(function () {
    'use strict';

    const pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.constant('englishTranslations', {
        general: {
            appTitle: 'DTA Universal',
            cancel: 'Cancel',
            save: 'Save',
            add: 'Add',
            ok: 'Ok',
            close: 'Close',
            on: 'on',
            off: 'off',
            no: 'No',
            yes: 'Yes',
            followMe: 'Follow me',
            undo: 'Back',
            send: 'Send',
        },
        footer: {
            sort: 'Sort',
            auto: 'Auto',
            autoModeConfirmation: 'Are you sure you want to activate automatic reporting based on your location and speed?',
            noDeletableOrders: 'There are no orders that can be deleted. Only orders older than 1 day can be deleted.',
        },
        routeStopCard: {
            deviation: 'Deviation',
            battery: 'Battery',
            events: 'Events',
            navigation: 'Navigation',
            info: 'Info',
            addArticle: 'Add Article',
            no: 'No',
            yes: 'Yes',
            delete: 'Delete',
            number: 'Number',
            product: 'Product',
            today: 'Today',
            oneToThreeDays: '1-3 days',
            fourToSixDays: '4-6 days',
            sevenToFourteenDays: '7-14 days',
            moreThanFourteenDays: '> 14 days',
            goBackConfirmation: 'Are you sure you want to go back? Data is not stored.',
            units: 'Units',
            comment: 'Kommentar',
            deviationCategories: 'Deviation categories',
            favorites: 'Favorites',
            dataRegistration: 'Data registration',
            details: 'Details',
            navigateTo: 'Navigate to',
            done: 'DONE',
            Databutton1: 'Weight',
            Databutton2: 'Extrabag',
            Databutton3: 'Fill (%)',
            Databutton4: 'Pap-ball',
            Databutton5: 'Extra waste',
            orDeviationRequired: 'or deviation is required!',
            isRequired: 'is required!',
            camera: {
                ok: 'Ok',
                close: 'Close',
                takePicture: 'Take picture',
                retakePicture: 'Retake picture'
            },
            containerEvents: {
                view: 'View',
                noRecords: 'No results',
                time: 'Date/time',
                comment: 'Comment',
                user: 'Vehicle',
                logAction: 'Log action'
            },
            containerStatistics: {
                noData: 'No statistics available',
                close: 'Close',
                signalStrength: 'Signal strength',
                battery: 'Battery',
                temperature: 'Temperature',
                fillLevel: 'Fill level',
                accessCount: 'Access count',
                usage: 'Usage',
                tooltipTemplate: 'Day: #= category #<br />#= series.name #: #= value #',
            },
            deviations: {
                removeAll: 'Remove all',
                selectAll: 'Select all',
                resetSelectedOnes: 'Reset selected ones',
                allEmpty: 'Are all emptied?',
                isEmpty: 'Is it emptied?',
                qrCode: 'QR code',
                comment: 'Comment',
                picture: 'Picture',
                selectDeviationCategory: 'Select deviation category',
                allCategories: 'All categories',
                ok: 'Ok',
                resetConfirmation: 'Do you want to remove all registrations, comments and photos?',
                removeAllRegistrationsTitle: 'Remove all registrations?',
                pictureAndCommentRequiredWarning: 'Picture and comment are required for this type of deviation',
                commentRequiredWarning: 'Comment is required for this type of deviation',
                pictureRequiredWarning: 'Picture is required for this type of deviation',
                unitMustBeSelectedError: 'At least one unit must be selected to add a deviation/note.',
                imageListOpenError: 'At least one unit must be selected to add a picture.',
                unknownError: 'Unknown error',
                profileInfoOfflineMsg: 'Information not available without Internet connection.',
                noRequiredDeviation: 'No requires at least one deviation',
                picture: 'Picture',
                comment: 'Comment',
                qrCode: 'QR code',
                startCamera: 'Starting camera',
                cameraIsNotAvailable: 'Camera is not available. Please close other applications that use the camera.',
                deviation: 'Deviation',
                newPicture: 'New picture',
            },
            routeStopInfo: {
                latitude: 'Latitude:',
                longitude: 'Longitude:',
                accuracy: 'Accuracy:',
                adjustPoint: 'Adjust point',
                adjustSludgeTank: 'Adjust sludge tank',
                adjustFailed: 'Adjusting sludge tank failed',
            },
            weightSensorModal: {
                waitingMsg: 'Waiting for weight data',
                connectedToSensor: 'Connected to weight sensor:',
                noSensorConnected: 'No weight sensor is connected',
                kg: 'kg',
                registerManually: 'Register manually',
                total: 'Total:'
            },     
        },
        completedRouteStops: {
            number: 'Number',
            product: 'Product',
            delete: 'Delete',
            info: 'Info',
            undo: 'Undo',
            deviation: 'Deviation',
            noCompletedRoutestops: 'No completed route stops registered',
            couldNotLoadCompletedRoutestops: 'Could not load completed route stops - please check your connection',
            reportHasBeenSentAlert: 'Rapporten er sendt til fagsystem og kan ikke endres.',
            routeCompletedAlert: 'Rute fullført!',
        },
        routeStopsList: {
            printing: 'Printing...',
            ready: 'Ready',
            failed: 'Failed',
            messages: 'Messages',
            smsReceipt: 'SMS Receipt',
            warning: 'Warning!',
            selectReceiptType: 'Select receipt type',
            printReceipt: 'Print receipt',
            sendReceipt: 'Send receipt',
            routeCompletedAlert: 'Rute fullført!',
            routeExpired: 'Route expired',
            downloadFailed: 'Download failed, please try again. This may be due to a lack of storage space.',
            autoTurnedOff: 'By turning off auto-registration, receipts at the current stop point will not be automatically registered.',
            loading: 'Loading...',
            retrying: ' \nRetrying...',
            scrollAlertModal: {
                dontShow: 'Do not show again',
                text1: 'Scrolling long lists can cause performance problems.',
                text2: 'You can display stop points that lack position data by pressing "Without position"'
            }
        },
        nearByRouteStops: {
            distance: 'Distance',
            offlineMessageText: 'No internet connection. Unable to load nearby route stops',
            noData: 'No data.',
            loading: 'Loading...',
        },
        login: {
            login: 'LOGIN',
            logIn: 'LOGIN',
            username: 'Username',
            password: 'Password',
            samsungBrowserErrorMessage: 'Samsung browser is not supported, we recommend you to use chrome, otherwise the application might not work correctly',
            demoModeMessage: 'Demo mode - Not registered user for DTA Universal',
            incorrectLoginData: 'Incorrect username or password',
        },
        orderMenu: {
            logout: 'Logout',
            cannotLogOut: 'Cannot log out offline!',
            support: 'Support',
            settings: 'Settings',
        },
        inRouteMenu: {
            cancelOrder: 'Cancel order',
            workList: 'Worklist',
            undo: 'Undo',
            adhoc: 'Ad hoc registration',
            support: 'Support',
            fontSize: 'Font size:',
            normal: 'Normal',
            bigger: 'Bigger',
            biggest: 'Biggest',
            speed: 'Speed (automatic reporting):',
            detection: 'Detection filter:',
        },
        articles: {
            offlineMessageText: 'No internet connection.',
            number: 'Number',
        },
        callOrder: {
            general: 'General',
            units: 'Units',
            details: 'Details',
            refresh: 'Refresh',
            addSelected: 'Add',
        },
        orders: {
            selectDriver: 'Select driver',
            selectDriverErrorMessage: 'Select driver before loading the routes',
            samsungBrowserErrorMessage: 'Samsung browser is not supported, we recommend you to use chrome, otherwise the application might not work correctly',
            checkOutOrderErrorMessage: 'Cannot check out order while offline - please check your connection',
            confirmationMessage: 'Are you sure?',
            noOrders: 'No orders available',
            canNotLoadOrders: 'Could not download orders. Check your internet connection',
        },
        navbar: {
            callOrder: 'Callorder',
            settings: 'Settings',
            messages: 'Messages',
            selectOrderTypes: 'Select order types',
            selectAreas: 'Select areas',
            support: 'Support',
            nearByRouteStopRegistration: 'Random registration',
            cancelRouteConfirmation: 'Are you sure you want to cancel the route?',
            demoMode: 'DEMOMODE',
            noAreas: 'No areas',
            startSequence: 'Start sequence',
        },
        filters: {
            sequence: 'Sequence',
            description: 'Address',
            distance: 'Distance',
            fill: 'Fill %',
            volt: 'Volt',
            days: 'Days',
            containerId: 'ContainerNr',
            dateWhenFullToday: 'Priority: Today',
            dateWhenFullIn3Days: 'Priority: 1-3 days',
            orderData: {
                DetailData_Expires: 'Perform until',
            }
        },
        settings: {
            dataDeleteConfirmation: 'Are you sure you want to delete locally stored data?',      
            loggedInUser: 'Logged in user',
            clearCache: 'Cache clear',
            refreshSettings: 'Refresh settings',
            settingsUpdated: 'Settings updated'
        },
        support: {
            happyToHelp: 'We are happy to help you. Contact us at:',
            phone: 'Telephone',
            email: 'E-mail',
            sendReport: 'Send report',
            openDiagnostics: 'Open diagnostics',
            diagnosticsPanel: "Diagnostics panel",
            send: 'Send',
            message: 'Message:',
            reportSent: 'Report sent!',
            reportError: 'Error occured - report not sent!',
            messageRequiredError: 'Please provide a short message!',
        },
        diagnostics: {
            hi: 'Hi,',
            allRecords: 'All records',
            copied: 'Copied',
            copyIndexDB: 'Copy IndexedDB',
            copyRow: 'Copy row',
            downloadRow: 'Download row',
            downloadIndexDB: 'Download IndexedDB',
            downloading: 'Downloading...',
            noSelectedRecordMessage: 'Please select a key',
            key: 'Key',
            value: 'Value',
            beCareful: 'Be careful!',
            warningAllDataWillBeCleared: 'All current cache data will be cleared!',
            warningLoggedIn: 'Be logged in with the user that was in use when the file was exported!',
            warningUploadOnly: 'Upload only files that were downloaded in the preview step as ,,Download IndexedDB"!',
            errorCouldNotOpen: 'Could not open current IndexedDB!',
            errorCouldNotRewrite: 'Could not rewrite the IndexedDB! Please log out and log in again!',
            errorReadingFile: 'Error while reading the file!',
            errorInvalidData: 'Invalid input data',
            successfullyImported: 'Succesfully imported!',
            confirm: 'Are you sure?',
            uploadIndexDB: 'Upload IndexedDB',
        },
        errors: {
            browserError: 'Full version of PWA requires a different browser.',
            error_template: 'Invalid template',
            error_userData: 'Invalid user data',
            error_adjustVerticalSpacing: 'Adjust vertical spacing',
            error_createReceipt: 'Create receipt',
            error_connectingPrinter: 'Connecting printer',
            error_printerUnavailable: 'Printer is unavailable',
            error_sendingData: 'Sending data',
            error_establishingConnection: ' Establishing connection',
            error_printerIsBusy: 'Printer is busy',
            error_addressError: ' Address error',
            error_fillingTemplateData: 'Filling template data',
        },
        info: {
            seq: 'Seq',
            units: 'Units',
            address: 'Address',
            distance: 'Distance',
            status: 'Status',
            done: 'Done',
        },
        directMessage: {
            address: 'Address',
            sender: 'Sender',
            date: 'Date',
            mobileNumber: 'Mobile number',
            mobileNumberIsRequiredError: 'Mobile number is required!',
            mobileNumberValidationError: 'Minimum 8 and maximum 10 digits (\'+\' sign is optional)!',
            receiptText: 'Receipt text',
            sendReceipt: 'Send receipt',
            messages: 'Messages',
            ok: ' Ok',
        },
        multipleChoice: {
            choodeAnAlternative: 'Choose an alternative'
        }
    });

})();
